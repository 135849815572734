import React, { useEffect } from 'react'
import { Link, navigate } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFound = () => {
    useEffect(() => {
        navigate("/");
    },[]);
    return (
        <Layout>
            <SEO
                pagetitle="404"
                pageDesc="The 404 page of Aiki Ishin-ryu."
            />
            <h1>Page not found</h1>
            <p><Link to="/">Head home</Link></p>
        </Layout>
    )
}

export default NotFound